import React from 'react';
import { Router } from '@reach/router';
import ProjectDetail from './Projects/_ProjectDetail';

const Projects = () => {
  return (
    <Router>
      <ProjectDetail path="/projects/:id/" />
    </Router>
  );
};

export default Projects;
