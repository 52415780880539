import React from 'react';
import { projectDb } from 'lib/data';
import AbsolutelyCentered from 'components/AbsolutelyCentered';
import { Link } from 'gatsby';
import { useParams } from '@reach/router';
import cls from 'classnames';

const Projects = () => {
  const { id } = useParams();
  const project = projectDb[id];
  return (
    <div
      className={cls(
        'min-h-screen flex flex-col items-center md:items-start p-4',
        project.main,
        project.text,
      )}
    >
      <div
        data-label="breadcrumb"
        className={cls(
          'tw-breadcrumb py-2 px-4 my-4 rounded inline-block',
          project.secondary,
          project.text,
        )}
      >
        <Link data-label="link" to="/">
          <span className="hover:underline">Home</span>
        </Link>
        <a data-label="link" href="/#projects">
          <span className="hover:underline">Projects</span>
        </a>
        <span data-label="link">{project.title}</span>
      </div>

      <div className="flex flex-1 flex-col-reverse md:flex-row items-center">
        <div className="flex-1 p-4">
          <div className="flex items-center justify-between">
            <p className="inline-block text-4xl">{project.title}</p>
            {project.link && (
              <a
                className={cls('tw-btn text-white', project.btnTheme)}
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Page
              </a>
            )}
          </div>
          <ul className="list-disc">
            {project.long.map((description, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <li className="my-4" key={idx}>
                {description}
              </li>
            ))}
          </ul>
          <div
            className={cls(
              'inline-flex items-center px-2 py-4 rounded mt-4',
              project.secondary,
            )}
          >
            {project.technologies.map(({ label, src, link }) => (
              <div key={label} className="inline-block">
                <div className="tw-tooltip relative flex flex-col p-1 items-center hover:zoom-in-lg">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img alt={label} width={30} height={30} src={src} />
                    <AbsolutelyCentered
                      data-label="tooltip-content"
                      className={`top-full text-xs text-center ${project.dark} text-white p-1 rounded whitespace-no-wrap`}
                      axis="horizontal"
                    >
                      {({ ref }) => <p ref={ref}>{label}</p>}
                    </AbsolutelyCentered>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:flex-1 p-4">
          {project.useVideo ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls>
              <source src={project.preview} type="video/mp4" />
            </video>
          ) : (
            <img src={project.preview} alt="animated" />
          )}
        </div>
      </div>
    </div>
  );
};

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
